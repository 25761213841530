import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios"; // Import axios for API calls
import DrawerNav from "../Navigation/DrawerNav";
import { BASE_URL } from "../../Api/Constants";
import DeleteIcon from "@mui/icons-material/Delete"; // Icon for delete

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const ViewRecommendationManagement = () => {
  const [data, setData] = useState({ list: [] }); // Initialize state to hold API data
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    // Fetch data from the API when component mounts
    const fetchRecommendations = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/admin/v1/manage-recommendations`
        ); // Replace with your API endpoint
        if (response.data.data) {
          setData(response.data.data); // Set the data received from the API
        }
      } catch (error) {
        console.error("Error fetching recommendations:", error);
      } finally {
        setLoading(false); // Set loading to false after the API call
      }
    };

    fetchRecommendations();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${BASE_URL}/api/admin/v1/manage-recommendations/${id}`
      ); // Replace with your delete API endpoint
      setData((prevData) => ({
        ...prevData,
        list: prevData.list.filter((item) => item._id !== id), // Remove the deleted item from the state
      }));
    } catch (error) {
      console.error("Error deleting recommendation:", error);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <DrawerNav />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Recommendations
        </Typography>
        <TableContainer component={Paper} elevation={4}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Question ID</StyledTableCell>
                <StyledTableCell>Answer</StyledTableCell>
                <StyledTableCell>Title</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell>Link</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>{" "}
                {/* Added column for delete button */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.list.map((item) => (
                <React.Fragment key={item._id}>
                  {item.questions.map((question, index) => (
                    <StyledTableRow key={index}>
                      <TableCell>{question.questionId}</TableCell>
                      <TableCell>{question.answer}</TableCell>
                      {index === 0 && (
                        <>
                          <TableCell rowSpan={item.questions.length}>
                            {item.title}
                          </TableCell>
                          <TableCell rowSpan={item.questions.length}>
                            {item.type}
                          </TableCell>
                          <TableCell rowSpan={item.questions.length}>
                            {item.description}
                          </TableCell>
                          <TableCell rowSpan={item.questions.length}>
                            <Link
                              href={`${item.link}`}
                              target="_blank"
                              rel="noopener"
                            >
                              {item.link}
                            </Link>
                          </TableCell>
                          {/* Delete button, only for the first question row */}
                          <TableCell rowSpan={item.questions.length}>
                            <IconButton
                              color="error"
                              onClick={() => handleDelete(item._id)} // Call delete function
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </>
                      )}
                    </StyledTableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ViewRecommendationManagement;
