import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { BASE_URL } from "../../Api/Constants";
import axios from "axios";

export default function EditRecommendationDetailsModal({
  open,
  handleClose,
  article,
}) {
  const [articleDetails, setArticleDetails] = useState({
    name: "",
    description: "",
    route: "",
    backgroundImage: "",
  });

  useEffect(() => {
    if (article) {
      setArticleDetails({
        name: article.name,
        description: article.description,
        route: article.route,
        backgroundImage: article.backgroundImage,
      });
    }
  }, [article]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticleDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    console.log("Article : ", article);
    try {
      const response = await axios.put(
        `${BASE_URL}/api/admin/v2/recommendation/${article._id}`,
        articleDetails
      );
      console.log("response : ", response);
      if (response.status === 200) {
        handleClose();
      } else {
        console.error("Failed to update article details");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Recommendation Details</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          name="name"
          value={articleDetails.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description"
          name="description"
          value={articleDetails.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Route"
          name="route"
          value={articleDetails.route}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Background Image URL"
          name="backgroundImage"
          value={articleDetails.backgroundImage}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
