import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  Article,
  Home,
  Logout,
  Person,
  Recommend,
  Search,
  Settings,
} from "@mui/icons-material";
function DrawerNav() {
  const navigate = useNavigate();
  const handleRoute = (url) => {
    navigate("/" + url);
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  const contestListItems = [
    {
      value: "Home",
      route: "",
      icon: <Home />,
    },
    {
      value: "Upload Media",
      route: "upload-media",
      icon: <Recommend />,
    },
  ];

  const articleItems = [
    {
      value: "Add Article",
      route: "add-article",
      icon: <Article />,
    },
    {
      value: "View Articles",
      route: "articles",
      icon: <Article />,
    },
  ];

  const recommendationItems = [
    {
      value: "Add Recommendation",
      route: "add-recommendation",
      icon: <Recommend />,
    },
    {
      value: "View Recommendation",
      route: "recommendations",
      icon: <Recommend />,
    },
    {
      value: "Create Recommendation",
      route: "create-recommendation",
      icon: <Settings />,
    },
    {
      value: "Recommendation Lookup",
      route: "view-recommendation-management",
      icon: <Settings />,
    },
  ];

  const mainListItems3 = [
    {
      value: "Users",
      route: "users",
      icon: <Person />,
    },
    {
      value: "Search Users",
      route: "search-users",
      icon: <Search />,
    },
  ];

  const drawerWidth = 300;

  return (
    <>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          display: isMobile ? "none" : "block",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {contestListItems.map((text) => (
              <ListItem key={text.value} disablePadding>
                <ListItemButton onClick={() => handleRoute(text.route)}>
                  <ListItemIcon>{text.icon}</ListItemIcon>
                  <ListItemText primary={text.value} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {articleItems.map((text) => (
              <ListItem key={text.value} disablePadding>
                <ListItemButton onClick={() => handleRoute(text.route)}>
                  <ListItemIcon>{text.icon}</ListItemIcon>
                  <ListItemText primary={text.value} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {recommendationItems.map((text) => (
              <ListItem key={text.value} disablePadding>
                <ListItemButton onClick={() => handleRoute(text.route)}>
                  <ListItemIcon>{text.icon}</ListItemIcon>
                  <ListItemText primary={text.value} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {mainListItems3.map((text) => (
              <ListItem key={text.value} disablePadding>
                <ListItemButton onClick={() => handleRoute(text.route)}>
                  <ListItemIcon>{text.icon}</ListItemIcon>
                  <ListItemText primary={text.value} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            <ListItem key={12} disablePadding>
              <ListItemButton
                onClick={() => {
                  localStorage.clear();
                  handleRoute("login");
                }}
              >
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
}

export default DrawerNav;
