import { toast } from "react-toastify";

export const SHOW_TOAST = (message, type = "success") => {
  const formattedMessage = message
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  toast(formattedMessage, {
    type,
    position: "bottom-left",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
