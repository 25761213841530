import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  capitalize,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import DrawerNav from "../Navigation/DrawerNav";
import { BASE_URL } from "../../Api/Constants";
import { useNavigate } from "react-router-dom";
import EditRecommendationDetailsModal from "./EditRecommendationDetailsModal";

export default function ViewArticles() {
  const navigate = useNavigate();
  const [examsList, setExamsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);

  const fetchOnboardingScreens = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/user/v1/recommendations`);
      if (response.ok) {
        const data = await response.json();
        setExamsList(data.data.list);
      } else {
        console.error("Failed to fetch onboarding screens");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOnboardingScreens();
  }, []);

  const handleEdit = (screenId) => {
    navigate("/recommendation?id=" + screenId);
  };

  const handleAddContent = (screenId) => {
    navigate("/recommendation-v2?id=" + screenId);
  };

  const handleEditContent = (screenId) => {
    navigate("/recommendation-v3?id=" + screenId);
  };

  const handleCopyURL = (text) => {
    const url = `https://fertilityvibes.in/recommendation/${text}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {})
      .catch((err) => {});
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/admin/v1/recommendation/${articleToDelete}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setOpenDeleteDialog(false);
        setArticleToDelete(null);
        fetchOnboardingScreens();
      } else {
        console.error("Failed to delete article");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleOpenEditModal = (article) => {
    setSelectedArticle(article);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedArticle(null);
    fetchOnboardingScreens();
  };

  const handleOpenDeleteDialog = (articleId) => {
    setArticleToDelete(articleId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setArticleToDelete(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <DrawerNav />
      <Box component="main" sx={{ flexGrow: 1, p: 3, color: "#524d4d" }}>
        <Box p={2}>
          <Typography variant="h4" sx={{ fontWeight: "bolder", mb: 4 }}>
            Recommendations
          </Typography>
          {loading ? (
            <Typography sx={{ my: 2 }}>Loading...</Typography>
          ) : examsList.length === 0 ? (
            <Typography sx={{ my: 2 }}>No recommendation found!</Typography>
          ) : (
            <Box>
              {examsList.map((screen, index) => (
                <Card key={index} className="mb-8">
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography gutterBottom variant="h5" component="div">
                          {screen.name} - Type: {capitalize(screen.type)}
                        </Typography>
                        <IconButton
                          onClick={() => handleOpenEditModal(screen)}
                          sx={{ mb: 1 }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleOpenDeleteDialog(screen._id)}
                          sx={{ mb: 1 }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {screen.description}
                    </Typography>
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEdit(screen._id)}
                        sx={{ mr: 2 }}
                      >
                        Rearrange
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleEditContent(screen._id)}
                        sx={{ mr: 2 }}
                      >
                        Edit Content
                      </Button>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleAddContent(screen._id)}
                        sx={{ mr: 2 }}
                      >
                        Add Content
                      </Button>
                      <Button
                        variant="outlined"
                        color="info"
                        onClick={() => handleCopyURL(screen.route)}
                      >
                        Copy URL
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      {selectedArticle && (
        <EditRecommendationDetailsModal
          open={openEditModal}
          handleClose={handleCloseEditModal}
          article={selectedArticle}
        />
      )}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this article?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
