exports.PROFILING_QUESTIONS = [
    { id: "menstrual_regularity", title: "Menstruation Regularity", options: ["Regular", "Irregular", "Somewhat Regular"] },
    { id: "endometriosis", title: "Endometriosis", options: ["Y", "N", "Don’t Know"] },
    { id: "adenomyosis", title: "Adenomyosis", options: ["Y", "N", "Don’t Know"] },
    { id: "sperm_volume", title: "Sperm Health- Volume", options: ["All well", "Some issue detected", "Don’t know"] },
    { id: "sperm_acidity", title: "Sperm Health- pH/Acidity", options: ["All well", "Some issue detected", "Don’t know"] },
    { id: "sperm_count", title: "Sperm Health- Count", options: ["All well", "Some issue detected", "Don’t know"] },
    { id: "sperm_motility", title: "Sperm Health- Progressive motility", options: ["All well", "Some issue detected", "Don’t know"] },
    { id: "sperm_morphology", title: "Sperm Health- morphology", options: ["All well", "Some issue detected", "Don’t know"] },
    { id: "amh", title: "AMH (ng/ml)", options: [".4-1", "1-3", ">3", "don’t know"] },
    { id: "lh", title: "LH (IU/L)", options: ["5-25", "14.2-52.3", "Other", "Don’t know"] },
    { id: "fsh", title: "FSH (IU/L)", options: ["4.5 to 21.5", "Other", "Don’t know"] },
    { id: "fallopian_tube_health", title: "Fallopian Tube Health", options: ["All Well", "Blockage in One tube", "Blockage in both tubes", "Don’t know"] },
    { id: "any_critical_illness", title: "Any Critical Illness", options: ["Y", "N"] },
    { id: "previous_ivf_cycle", title: "Previous IVF cycle", options: ["Y", "N"] },
    { id: "period_pain", title: "Period Pain", options: ["No Pain", "Bearable", "Unbearable", "debilitating"] },
    { id: "period_cycle", title: "Period Cycle", options: ["<21 days", "28-35 days", ">45 days", "No fixed interval"] },
    { id: "bleeding_days", title: "Bleeding days", options: ["<3 days", "3-7 days", ">7 days"] },
    { id: "bleeding_pattern", title: "Bleeding Pattern", options: ["Light Bleeding", "Heavy bleeding till day2/3- Light afterward", "Heavy bleeding till day 5", "heavy bleeding for >5 days"] },
    { id: "clots", title: "Clots?", options: ["Y", "N"] },
    { id: "pimple_acne_during_period", title: "Pimple/Acne during period", options: ["Y", "N"] },
    { id: "hair_growth_jaw_upper_lip", title: "Hair growth in jaw and upper lip", options: ["Y", "N"] },
    { id: "feel_stressed", title: "Do you feel stressed", options: ["Y", "N"] },
    { id: "skin_darkening_around_lips", title: "Skin Darkening around lips", options: ["Y", "N"] },
    { id: "recurrent_vaginal_infection", title: "Recurrent vaginal infection", options: ["Y", "N"] },
    { id: "burning_sensation_urination", title: "Burning sensation while Urination", options: ["Y", "N"] },
    { id: "unable_hold_urine", title: "Unable to hold Urine", options: ["Y", "N"] },
    { id: "painful_intercourse", title: "Painful Intercourse", options: ["Y", "N"] },
    { id: "biological_children", title: "Do you already have your biological children", options: ["Y", "N"] },
    { id: "frozen_egg", title: "Frozen Egg", options: ["Y", "N"] },
    { id: "frozen_embryos", title: "Frozen Embryos", options: ["Y", "N"] },
    { id: "previous_miscarriage", title: "Previous Miscarriage", options: ["0", "1", "2", "3", ">3"] },
    { id: "age", title: "Age", options: ["18-25", "26-32", "33-35", "36-40", "40-45", "45+"] },
    { id: "food_preference", title: "Food Preference", options: ["Gluten free", "Vegetarian", "Eggetarian", "Non-Veg", "Others"] },
    { id: "pcos_pcod", title: "PCOS/PCOD", options: ["Y", "N", "Don’t Know"] },
];